import { render, staticRenderFns } from "./7-UsVIPCourse.vue?vue&type=template&id=39d1acda&scoped=true"
import script from "./7-UsVIPCourse.vue?vue&type=script&lang=js"
export * from "./7-UsVIPCourse.vue?vue&type=script&lang=js"
import style0 from "./7-UsVIPCourse.vue?vue&type=style&index=0&id=39d1acda&prod&scoped=scoped&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "39d1acda",
  null
  
)

export default component.exports